<template>
  <div class="content">
    <div class="goTo" @click="goHome">
      首页
    </div>
    <div class="logo">
     
    </div>
    <div class="swiper-container" id="swiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in imgList" :key="index">
          <div
            class="img "
            :style="{
              background: 'url(' + item.image + ')  no-repeat center center ',
            }"
          ></div>
          <div class="txt ">
            <h4 v-if="data.seriesId===1">寓见高行·原宿</h4>
           <h4 v-if="data.seriesId===2">寓见高行·安居</h4>
           <h4 v-if="data.seriesId===3">寓见高行·宜居</h4>
           <h4 v-if="data.seriesId===4">寓见高行·品质</h4>
           <h4 v-if="data.seriesId===5">寓见高行·卓越</h4>
            <p
            class="ani animated fadeInRight"
            >
              {{ data.introduction }}
            </p>
          </div>
        </div>
      </div>
      <div class="swiper-button-prev swiper-button-white">
         <van-icon class="initIcon" name="arrow-left" />
      </div>
      <div class="swiper-button-next swiper-button-white">
         <van-icon class="initIcon" name="arrow" />
      </div>
      <div class="swiper-pagination"></div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper"
import "swiper/dist/css/swiper.min.css"
// import {swiperAnimateCache,swiperAnimate} from '@/utils/swiper.animate'
import { project } from "@/api/project"
export default {
  components: {},
  data() {
    return {
      imgList: [
       
      ],
      activeIndex:0,
      data:{},
    };
  },
  computed: {},
  watch: {},
  methods: {
    goHome(){
        this.$router.push({
        path: "/",
       
      });
    },
     async getData() {
        let _this = this;
      let id = this.$route.query.id;
      let { result } = await project({ id: id });
      this.imgList = result[0].images;
      this.data = result[0];
        setTimeout(function () {
      new Swiper("#swiper", {
        loop: false,
        prevButton: ".swiper-button-prev",
        nextButton: ".swiper-button-next",
        pagination: ".swiper-pagination",
        paginationClickable: true,
        onInit: function (swiper) {
          console.log(document.querySelectorAll('.ani'));
          document.querySelectorAll('.ani')[0].style.display="block"
        
        },
        onTransitionStart(swiper){
          _this.activeIndex = swiper.activeIndex;
           
        },
        onSlideChangeEnd: function (swiper) {
          // console.log(swiper);
           document.querySelectorAll('.ani')[swiper.activeIndex].style.display="block"
        },
        onTransitionEnd: function (swiper) {
          console.log('结束动画');
         let doc = document.querySelectorAll('.ani')
         doc.forEach((el,index)=>{
           console.log(index,_this.activeIndex);
           if(_this.activeIndex!==index){
             el.style.display="none"
           }
           
         })
        },
      });
    }, 100);
    },
  },
  created() {
    this.getData()
  },
  mounted() {
   
  
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
}
</script>
<style lang="scss" scoped>
//@import url(); 引入公共css类

.swiper-container {
  height: 100%;
  .swiper-slide {
    color: #fff;
    .img {
      width: 100%;
      height: 100%;
      background-size: cover !important;
    }
    .txt {
      position: absolute;
      width: 100%;
      height: 420px;
      bottom: 0;
      padding: 200px 90px 0 90px;
      background-image: linear-gradient(
        to top,
        rgba(56, 140, 136, 1),
        rgba(56, 140, 136, 0) 100%
      );
      box-sizing: border-box;
    
      h4 {
        font-size: 20px;
        letter-spacing: 10px;
      }
      p {
        font-size: 20px;
        color: #e0fdfd;
        text-indent: 40px;
          
       
      }
    }
  }

  /deep/ .swiper-pagination-bullet {
    background: #989898;
    opacity: 1;
  }
  /deep/ .swiper-pagination-bullet-active {
    background: #fff;
  }
  /deep/ .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
  }
  /deep/ .swiper-wrapper {
    transition: all 0.5s linear !important;
  }
}
.ani{
   display: none;
}
.goTo{
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 999999;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #388c88;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.swiper-button-white{
 background-image:initial;
}
/deep/ .initIcon{
      width: 27px;
    height: 44px;
    font-size: 44px;
    color: #fff;
    &:hover{
      color: #388c88;
    }
}
/deep/ .swiper-button-next, .swiper-button-prev{
  width: 44px;
}
</style>
